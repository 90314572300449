<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 select-cursor"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div
              v-if="$ability.can('create', 'Utilisateur')"
              class="d-flex align-items-center justify-content-end"
            >
              <b-button
                variant="primary"
                @click="onAddUserButtonClicked()"
              >
                <span class="text-nowrap">Ajouter un utilisateur</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchData"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun utilisateur n'a été trouvé"
        :sort-desc.sync="isSortDirDesc"
        :no-provider-sorting="true"
        :no-provider-paging="true"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$ability.can('update, Utilisateur')"
              :to="{ name: 'update-user', params: { id: data.item.uid } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('action.edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$ability.can('delete', 'Utilisateur')"
              @click="deleteConfirm(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('action.delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }}-{{ dataMeta.to }} {{ $t('on') }} {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import router from '@/router'
import useUsersList from './useUsersList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const onAddUserButtonClicked = () => {
      router.push({ name: 'add-user' })
    }

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
    } = useUsersList()

    return {
      // event
      onAddUserButtonClicked,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
    }
  },

  methods: {
    deleteConfirm(item) {
      this.$bvModal.msgBoxConfirm(`Confirmer vous la suppression du compte utilisateur ${item.prenom} ${item.nom} ?`, {
        title: 'Suppression compte utilisateur',
        size: 'sm',
        okTitle: 'Oui',
        cancelTitle: 'Non',
      }).then(confirm => {
        if (confirm) { this.deleteItem(item.uid) }
      })
    },
  },

}
</script>
