import useList from '@/views/hooks/useList'
import store from '@/store'
import moment from 'moment'

export default function useUsersList() {
  // DATA

  const list = useList({
    fetchAction: () => store.dispatch('users/initUsers'),
    deleteActionName: 'users/deleteUser',
  })

  const formatUtilisateurRole = role => {
    if (role === 'admin') {
      return 'Administrateur'
    }
    if (role === 'backoffice') {
      return 'Net&Co'
    }
    if (role === 'frontoffice') {
      return 'Client'
    }

    return ''
  }

  const tableColumns = [
    {
      label: 'Nom', key: 'nom', sortable: true,
    },
    { label: 'Prénom', key: 'prenom', sortable: true },
    { label: 'Rôle', key: 'utilisateurRole', formatter: value => formatUtilisateurRole(value) },
    {
      label: 'Dernière connexion', key: 'lastConnection', sortable: true, formatter: date => (date ? moment(date).format('DD/MM/YYYY') : ''),
    },
    { label: 'Actions', key: 'actions' },
  ]

  return {
    ...list,
    tableColumns,
  }
}
